@import 'assets/scss/app.scss';

#global-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 11;

  display: flex;
  justify-content: center;
  align-items: center;
}

#global-modal {
  background: white;
  border-radius: 15px;
  width: 80%;
  height: 80%;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.spinner.medium {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.spinner.small {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite;
}

.-pageSizeOptions {
  display: none;
}

label.required::after {
  content: " *";
  color: red;
}