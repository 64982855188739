/* FONT PATH
 * -------------------------- */

@font-face {
	font-family: 'FontAwesome';
	src: url('../../../assets/fonts/font-awesome/fontawesome-webfont.eot?v=#{$fa-version}');
	src: url('../../../assets/fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=#{$fa-version}')
			format('embedded-opentype'),
		url('../../../assets/fonts/font-awesome/fontawesome-webfont.woff2?v=#{$fa-version}')
			format('woff2'),
		url('../../../assets/fonts/font-awesome/fontawesome-webfont.woff?v=#{$fa-version}')
			format('woff'),
		url('../../../assets/fonts/font-awesome/fontawesome-webfont.ttf?v=#{$fa-version}')
			format('truetype'),
		url('../../../assets/fonts/font-awesome/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular')
			format('svg');
	//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
	font-weight: normal;
	font-style: normal;
}
