@keyframes show-select-video-modal {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.select-video-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
}

.select-video-modal {
  background-color: white;
  border-radius: 5px;
  height: 95%;
  width: 95%;
  position: relative;
  animation: show-select-video-modal 0.5s;
  overflow: hidden;
}

.select-video-modal .header {
  width: 100%;
  padding: 10px 0;
  border-bottom: solid 2px #bcbcbc;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-video-modal .modal-title {
  font-size: 22px;
  margin-right: 32px;
  margin-left: 8px;
}

.select-video-modal .medias {
  margin-top: 5px;
  height: 90%;
  width: 100%;
  overflow: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.select-video-modal .media {
  width: 300px;
  height: 250px;
  margin: 5px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.select-video-modal .media:hover {
  background-color: rgba(228, 64, 35, 0.4);
  border-radius: 5px;
}

.select-video-modal .media-thumbnail-container {
  width: 100%;
  height: 70%;
  margin-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.select-video-modal .media-thumbnail {
  width: 100%;
  height: 100%;
}

.select-video-modal .media-title {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding: 0 5px;
}

.select-video-modal .media-postdate {
  width: 100%;
  padding: 0 5px;
}

.select-video-modal .media-metadata-container {
  height: 25%;
}

.select-video-modal .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
}
